
:root {
    --header-height: 120px;

    --theme-primary-color: #1f1f1f;
    --theme-secondary-color: #ffffff;
    --theme-nav-color: #aeaeae;
    --theme-surface-color: #efefef;

    --mdc-theme-primary: #1f1f1f;
    --mdc-theme-on-primary: #ffffff;

    --mdc-theme-secondary: #ffffff;
    --mdc-theme-on-secondary: #1f1f1f;

    /* webkit */
    --scrollbar-track-color: #dfdfdf;
    --scrollbar-track-border: transparent;
    --scrollbar-thumb-color: #aeaeae;
    --scrollbar-thickness: 15px;

    --range-track-color: #dfdfdf;
    --range-track-border: transparent;
    --range-track-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    --range-track-thickness: 8px;
    --range-track-border-radius: 4px;

    --range-thumb-color: #1f1f1f;
    --range-thumb-border: solid 4px #ffffff;
    --range-thumb-width: 24px;
    --range-thumb-height: 24px;
    --range-thumb-border-radius: 24px;
    --range-thumb-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    
    --app-width: 100vw;
    --inverse-app-width: -100vw;
    --app-height: 100vh;
    --inverse-app-height: -100vh;
    --half-app-height:50vh;

    --is-portrait: 0;

    /* firefox 64 */
    --scrollbar-style: auto;

    /* to enable/disable scrollbar everywhere, set to none in javascript */
    --scrollbar-display: block;
}

html {
    -webkit-text-size-adjust: none;
    overflow:hidden;
}

/**
 * RESET
 * Reference: http://meyerweb.com/eric/tools/css/reset/
 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

code, pre, samp {
    font-family: monospace;
}

/**
 * BASIC TYPE
 */

img, video {
    max-width: 100%;
    vertical-align: middle;
}

p {
    margin: 0 0 1em;
}

h1, h2, h3, h4 {
    margin: .25em;
    line-height: 1.3;
}

h1 {
    font-size: 2.5em;
}

h2 {
    font-size: 1.25em;
}

h3 {
    font-size: 1.125em;
}

h4 {
    font-size: 1em;
}

/**
 * BOX HACKING
 */

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

/**
 * CLEAR FLOATS
 * Reference: http://w3bits.com/clearfix
 */

.cf:after {
    content: "";
    display: table;
    clear: both;
}

body {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: var(--theme-primary-color);
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

button:focus {outline:none;}

button {
    border:transparent;
    background: #ffffff;
    user-select: none;
}

i.arrow {
    border: solid var(--theme-nav-color);
    border-width: 0 1.5px 1.5px 0;

    display: inline-block;
    padding: 6px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-right: 1.5em;
    padding-left: 1.5em;
    max-width: 1080px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.page-content {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}

.centered-listing {
    text-align: left;
    margin:auto;
}

/* Slider Styling https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */

/* Slider - Clear everything for all browsers */
input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

/*input[type=range]::-ms-track {*/
/*    width: 100%;*/
/*    cursor: pointer;*/

/*    !* Hides the slider so custom styles can be added *!*/
/*    background: transparent;*/
/*    border-color: transparent;*/
/*    color: transparent;*/
/*}*/

/* Slider - Thumb - styling */

/* Slider - Thumb - Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: solid 4px #ffffff;
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background: #1f1f1f;
    cursor: pointer;
    margin-top: calc(24px / -3); /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}

/* Slider - Thumb - All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 4px #ffffff;
    height: 24px;
    width: 24px;
    border-radius: 100px;
    background: #1f1f1f;
    cursor: pointer;
}

/* Slider - Track - styling */

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #dfdfdf;
    border-radius: 4px;
    border: transparent;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #dfdfdf;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #dfdfdf;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: transparent;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px;
}

.fake-input {
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
}

.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

/* media orientations */

@media (orientation: portrait) {
    .wrapper {
        max-width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    .page-heading {
        height: 80px;
        width: 100%;
    }
    .page-heading h2 {
        line-height: 80px;
        font-size: 20px;
        margin-left: -50px;
    }

    .page-heading a.navigation:link, a.navigation:visited, a.navigation:hover {
        width: 56px;
        height: 24px;
        line-height: 80px;
        padding-left:5px;
        padding-right:5px;

        font-size: 15px;
        font-weight: bold;
    }
}

.md-modal {
    margin: auto;
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    width: 50%;
    max-width: 630px;
    min-width: 320px;
    height: auto;
    z-index: 9999;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.md-show {
    visibility: visible;
}

.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 9998;
    opacity: 0;
    background: rgba(1.0, 1.0, 1.0, 0.75);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.md-show ~ .md-overlay {
    opacity: 1;
    visibility: visible;
}

.md-content {
    display: inline-block;
}

.md-effect-12 .md-content {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.md-show.md-effect-12 ~ .md-overlay {
    background-color: #ffffff;
    opacity: 0.75;
}

.md-effect-12 .md-content h3,
.md-effect-12 .md-content {
    background: transparent;
}

.md-show.md-effect-12 .md-content {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}


/* scrollbar customization (when visible) */
div {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width:none;
}

::-webkit-scrollbar {
    width: var(--scrollbar-thickness);
    height: var(--scrollbar-thickness);
    display: none;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
}

.scrollbar {
    overflow: hidden;
    /* ie 11 */
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollbar::-webkit-scrollbar {
    display: var(--scrollbar-display);
}

.scrollbar:hover {
    scrollbar-width: var(--scrollbar-style);
}

.scrollbar.horizontal:hover {
    overflow-x: auto;
}

.scrollbar.vertical:hover {
    overflow-y: auto;
}

@media (orientation: portrait) {
    .scrollbar.horizontal {
        overflow-x: auto;
    }
    .scrollbar.vertical {
        overflow-y: auto;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape)  {
    .scrollbar.horizontal {
        overflow-x: auto;
    }
    .scrollbar.vertical {
        overflow-y: auto;
    }
}

@media (orientation: portrait) and (max-width: 500px) {
    :root {
        --header-height: 80px;
    }

    h1 {
        font-size: 1.8em;
    }

    i.arrow {
        padding:4px;
        margin-bottom:1px;
        margin-right: 5px;
    }
}