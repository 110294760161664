
:root {
    --animation-duration: 0.5s;
}

.page-heading {
    height: var(--header-height);
    width: 100%;
}

.navigation-menu {
    height: var(--header-height);
    width: 100%;
    text-align: center;
}

.navigation-back-button-container {
    position: absolute;
    top:0;
    height: var(--header-height);

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
}

.navigation-heading-container {
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    text-transform: capitalize;
}

.page-heading h2 {
    font-size: 2.2em;
    font-weight: 100;
    font-style: normal;
    line-height: 1.2em;

    text-align: center;
    display:inline;
    padding: 0;
    margin: 0;
}

.navigation-back-button {
    background-color: transparent;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #aeaeae;
}

.page-heading button.navigation-menu i {
    margin-right:5px;
}

.navigation-page {
    position: fixed;
    top:0;

    width:100vw;
    height: var(--app-height);
    overflow-y:scroll;
    overflow-x: hidden;
    background: var(--theme-secondary-color);
}

@media (orientation: portrait) and (max-width: 500px) {
    .page-heading h2 {
        font-size: 1.2em;
        font-weight: bold;
    }

    .navigation-back-button {
        line-height: 1.0em;
        font-size: 1.0em;
    }
}

.forward {
    /*border: 2px solid green;*/
}

.backward {
    /*border: 2px solid red;*/
}

.selected.forward {
    animation: slide-in-left var(--animation-duration) forwards;
    -webkit-animation: slide-in-left var(--animation-duration) forwards;
}

.dismiss.forward {
    animation: slide-out-left var(--animation-duration) forwards;
    -webkit-animation: slide-out-left var(--animation-duration) forwards;
}

.selected.backward {
    animation: slide-in-right var(--animation-duration) forwards;
    -webkit-animation: slide-in-right var(--animation-duration) forwards;
}

.dismiss.backward {
    animation: slide-out-right var(--animation-duration) forwards;
    -webkit-animation: slide-out-right var(--animation-duration) forwards;
}

/*from left, forward */
@keyframes slide-in-left {
    0% { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(0%); }
}

@-webkit-keyframes slide-in-left {
    0% {transform: translateX(100%); }
    100% { transform: translateX(0%); }
}

@keyframes slide-out-left {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out-left {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

/*from right, back button */
@keyframes slide-in-right {
    0% { -webkit-transform: translateX(-100%); }
    100% { -webkit-transform: translateX(0%); }
}

@-webkit-keyframes slide-in-right {
    0% {transform: translateX(-100%); }
    100% { transform: translateX(0%); }
}

@keyframes slide-out-right {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out-right {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
}